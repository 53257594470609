import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const propTypes = {
  title: PropTypes.string,
  metadesc: PropTypes.string,
  linkdex: PropTypes.string,
  meta_robots_noindex: PropTypes.string,
  meta_robots_nofollow: PropTypes.string,
  canonical: PropTypes.string,
  opengraph_title: PropTypes.string,
  opengraph_description: PropTypes.string,
  opengraph_image: PropTypes.string,
  twitter_title: PropTypes.string,
  twitter_description: PropTypes.string,
  twitter_image: PropTypes.string,
  schema: PropTypes.object
};

const MetaData = (props) => {

  const {
    title,
    metadesc,
    linkdex,
    meta_robots_noindex,
    meta_robots_nofollow,
    canonical,
    opengraph_title,
    opengraph_description,
    opengraph_image,
    twitter_title,
    twitter_description,
    twitter_image,
    schema
  } = props;

  return (
    <Helmet>

      {title && <title>{title}</title>}

      {metadesc && <meta name="description" content={metadesc} />}

      {canonical && <link rel="canonical" href={canonical} />}

      {opengraph_title && <meta property="og:title" content={opengraph_title} />}

      {opengraph_description && <meta property="og:description" content={opengraph_description} />}

      {opengraph_image && <meta property="og:image" content={opengraph_image} />}

      {twitter_title && <meta name="twitter:title" content={twitter_title} />}

      {twitter_description && <meta name="twitter:description" content={twitter_description} />}

      {twitter_image && <meta name="twitter:image" content={twitter_image} />}

      {meta_robots_nofollow && <meta name="robots" content="nofollow" />}

      {meta_robots_noindex && <meta name="robots" content="noindex" />}

      {linkdex && <meta name="linkdex" content={linkdex} />}

      {schema && <script type="application/ld+json">{JSON.stringify(schema)}</script>}

      <html lang="en" />
      <link rel="icon" type="image/x-icon" href="/favicon-rp.ico" />
      <link rel="apple-touch-icon" href="/apple-touch-icon-114x114.png" />
    </Helmet>
  );
};

MetaData.propTypes = propTypes;

export default MetaData;
